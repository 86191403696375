import { environment } from './../environments/environment';
import {
  Component,
  ChangeDetectorRef,
  EventEmitter,
  Output, OnInit, Inject, AfterViewChecked
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material';
import { DOCUMENT, Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from './services/data.service';
import { KeyValueService } from './services/key-value.service';
import { RetrieveIPAddress } from './services/retrieveipaddress.service';
import { UrlsService } from './services/urls.service';
import { CookieAndFooterServiceService } from './services/cookie-and-footer-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewChecked, OnInit {
  title: string;
  currentUser: boolean;
  mobileQuery: MediaQueryList;
  previousPage: string;
  showBackButton: boolean;
  showHomeButton: boolean;
  showContent = false;
  ipAddress = '';
  link  = '';
  requestUrl = '';
  referrer = '';
  showFooter : boolean = false
  // Navigation for Not Logged in User
  publicNavigation = [
    {
      title: 'Home',
      path: '/'
    },
    {
      title: 'My Account',
      path: '/signin'
    },
    {
      title: 'Sign Up',
      path: '/signup'
    },
    {
      title: 'Privacy Policy',
      path: '/privacypolicy'
    },
  ];
  // Navigation for Logged in User
  signedNavigation = [
    {
      title: 'Home',
      path: '/home'
    },    
    {
      title: 'My Profile',
      path: '/profile'
    },
    {
      title: 'Privacy Policy',
      path: '/privacypolicy'
    },
  ];
  private mobileQueryListener: () => void;
  @Output() toggleSideNav = new EventEmitter();
  toggleMobileNav(publicNavigation: MatSidenav) {
    if (this.mobileQuery.matches) {
      publicNavigation.toggle();
    }
  }

  constructor(@Inject(DOCUMENT) private document,
              private changeDetectorRef: ChangeDetectorRef,
              private media: MediaMatcher,
              private dataService: DataService,
              private keyValueService: KeyValueService,
              public router: Router,
              private location:Location,
              private activatedRouter: ActivatedRoute,
              private retreveIPAddress: RetrieveIPAddress,
              private urlsService: UrlsService,
              public  cookieAndFooterServiceService : CookieAndFooterServiceService,
              ) {
  }

  ngOnInit() {

      // console.log('cookieAndFooterServiceService=>',this.cookieAndFooterServiceService.showFooterAndCookies());
      
    if (environment.SecureSocketsLayerEnabled && window.location.protocol === 'http:') {
      window.location.protocol = 'https:'
    }
    const pathname = window.location.pathname;
    
    if (pathname.indexOf('/u/') >= 0 || pathname.indexOf('/q/') >= 0){
      this.RedirectPage(pathname);
    }
    else{
      
      this.showContent = true;
      this.mobileQuery = this.media.matchMedia('(max-width: 600px)');
      this.mobileQueryListener = () => this.changeDetectorRef.detectChanges();
      // tslint:disable-next-line: deprecation
      this.mobileQuery.addListener(this.mobileQueryListener);

      this.dataService.currentUser.subscribe(x => this.currentUser = x);
      this.keyValueService.GetAllValue().subscribe((keyValue) => {
        localStorage.setItem('keyValues', JSON.stringify(keyValue));
        if (keyValue && keyValue.length) {
          keyValue.forEach(k => {
            switch (k.VariableName) {
              case 'FavIcon':
                this.document.getElementById('lnkFavicon').setAttribute('href', (k.VariableValue));
                break;
              case 'CorporateAppName':
                this.document.title = k.VariableValue;
                this.title = k.VariableValue;
                break;
            }
          });
        } else if (!keyValue || keyValue.length <= 0) {
          // TODO: add proper logging
          // console.log('Invalid token');
        }
      });
      this.ShowHomeBackButton();
    }  
    // this.keyValueService.GetAllValue().subscribe((data) => {

    //   data.forEach((item)=>{
    //     this.map.set(item.VariableName,item.VariableValue) ;
    //   })
    //   console.log("Map=>",this.map);
      
    //   console.log("key-value-pair=>",data);
    //   this.dataService.setList(this.map)  ;
      
    // })
  }

  RedirectPage(pathname:string){
    const hash = pathname.replace('/u/', '').replace('/q/','');
    const routing = pathname.split('/')[1];
    switch(routing){
      case 'u':
        this.referrer = 'DIRECT';
        break;
      case 'q':
        this.referrer = 'QRCODE';
        break;
      default:
        this.referrer = 'UNKNOWN.' + routing + '.SOURCE';
        break;
    }
    if(document.referrer !== '') {
      this.referrer = this.parseURL(document.referrer).toUpperCase();
    }
      this.retreveIPAddress.getIpAddress().subscribe(data => {
        this.ipAddress = data.ip;
        this.urlsService.GetUrlFromHash(hash, this.ipAddress, this.referrer).subscribe((redirectLink) => {
          if (redirectLink)
          {
            location.href = String(redirectLink);
          }
          else{
            this.router.navigate(['/notfound']);
          }
        });
      });
  }

  parseURL(url:string){
		const parts:string[] = url.split('/');
		for(const part of parts){
      if(part.includes('.')){
        if(part.includes('?')) {
          return part.split('?')[0];
        }
				return part;
      }
		}
    return 'UNKNOWN';
	}

  ngAfterViewChecked(){
    this.ShowHomeBackButton();
    this.changeDetectorRef.detectChanges();
  }

  backClicked(){
    if (location.pathname.toUpperCase() !== '/HOME')
    {
      localStorage.setItem('backClicked', '0');
    }
    else
    {
      localStorage.setItem('backClicked', '1');
    }
    this.location.back();
  }

  ShowHomeBackButton(){
    switch(this.router.url.toUpperCase()){
      case '/SIGNIN':
        this.showBackButton = false;
        this.showHomeButton = false;
        break;
      case '/FORGETPASSWORD':
        this.showBackButton = true;
        this.showHomeButton = false;
        break;
      case '/SIGNUP':
        this.showBackButton = true;
        this.showHomeButton = false;
        break;
      case '/HOME':
        this.showBackButton = false;
        this.showHomeButton = false;
        break;
      default:
        this.showBackButton = true;
        this.showHomeButton = true;
        break;
    }
  }
}
