import { Component, EventEmitter, OnInit } from '@angular/core';
import { KeyValueService } from '../../services/key-value.service';

@Component({
  selector: 'app-accept-cookie-button',
  templateUrl: './accept-cookie-button.component.html',
  styleUrls: ['./accept-cookie-button.component.scss']
})
export class AcceptCookieButtonComponent implements OnInit {

  keyValuePair = new Map();
  constructor(
    private keyService: KeyValueService,
  ) { }

  ngOnInit() {
    this.getKeyValuePair().then((keyValue) => {
      if (this.keyValuePair) {
        // tslint:disable-next-line:max-line-length
        document.body.style.setProperty('--primaryBlueColour', this.keyValuePair.get('primaryBlueColour') ? this.keyValuePair.get('primaryBlueColour') : 'Blue')
      }
    });
  }

  getKeyValuePair(): Promise<any> {
    return new Promise((resolve, reject) => {
      const mapDataEmitter = new EventEmitter();
      this.keyService.GetAllValue().subscribe((data) => {
        // console.log('raw keyvalue data ==>', data);
        data.forEach((item) => {
          this.keyValuePair.set(item.VariableName, item.VariableValue);
        })
        resolve(this.keyValuePair);
      });
    })
  }

}
