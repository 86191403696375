import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieAndFooterServiceService {

  showFooter : boolean = false 

  constructor() { }

  showFooterAndCookies()
  {
    // if (window.innerWidth >= 400) { // 768px portrait

    //   this.showFooter = true

    // }
    // return this.showFooter 
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
  }
}
