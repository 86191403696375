import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

// Services
import { environment } from '../../environments/environment';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { UrlBody } from '../models/urls.model';
import { CustomerService } from './Customer.service';

@Injectable({
  providedIn: 'root'
})
export class UrlsService {
  private handleError: HandleError;
  public apiURL = environment.apiBaseUrl;
  public customerId : number;

  constructor(private httpClient: HttpClient,
              private httpErrorHandler: HttpErrorHandler,
              private customerService: CustomerService) {
    this.handleError = httpErrorHandler.createHandleError('UrlsService');

    this.customerId = this.customerService.getCustomerId();    
  }

  /**
   * Retrieves ket values pairs for the given token
   */

  public GetUrlFromHash(hash:string, ipAddress:string, referrer:string): Observable<any[]>{
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Token': environment.Token
    });
    return this.httpClient.get<any[]>(this.apiURL + '/urls/decodehash?hash=' + hash 
    + '&ipaddr=' + ipAddress + '&referrer=' + referrer, { headers: httpOptions })
    .pipe(
      catchError(this.handleError('GetUrlFromHash', []))
    );
  }

  public GetSpecificUrl(id: number): Observable<any>{
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Token': environment.Token
    });
    return this.httpClient.get<any>(`${this.apiURL}/urls?customerID=${this.customerId}&urlID=${id}`, { headers: httpOptions })
    .pipe(
      catchError(this.handleError('GetSpecificUrl'))
    );
  }

  public createUrl(urlBody: UrlBody): Observable<any>{
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Token': environment.Token
    });
    return this.httpClient.post(`${this.apiURL}/urls`, urlBody, { headers: httpOptions })
    .pipe(
      catchError(this.handleError('createUrl'))
    );
  }

  public updateUrl(urlBody: UrlBody, id: number): Observable<any>{
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Token': environment.Token
    });
    return this.httpClient.post(`${this.apiURL}/urls?customerID=${this.customerId}&urlID=${id}`, urlBody, { headers: httpOptions })
    .pipe(
      catchError(this.handleError('updateUrl'))
    );
  }

  public getAllUrl(): Observable<any>{
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Token': environment.Token
    });
    const id = this.customerService.getCustomerId();
    return this.httpClient.get(`${this.apiURL}/urls/getall?customerID=${id}`, { headers: httpOptions })
    .pipe(
      catchError(this.handleError('getAllUrl'))
    );
  }

  public GetUrlLogByDateRange(urlId :number, startingDate : string , endingDate : string ): Observable<any[]>{
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Token': environment.Token
    });
    return this.httpClient.get<any[]>(this.apiURL + '/urllog/usage?urlID=' + urlId 
    + '&startdate=' + startingDate + '&enddate=' + endingDate +'&customerID=' +`${this.customerId}`, { headers: httpOptions })
    .pipe(
      catchError(this.handleError('GetUrlLogByDateRange', []))
    );
  }

  public GetReferralsByCount(urlId :number, startingDate : string , endingDate : string ): Observable<any[]>{
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Token': environment.Token
    });
    return this.httpClient.get<any[]>(this.apiURL + '/urllog/referrals?urlID=' + urlId 
    + '&startdate=' + startingDate + '&enddate=' + endingDate +'&customerID=' +`${this.customerId}`, { headers: httpOptions })
    .pipe(
      catchError(this.handleError('GetReferralsByCount', []))
    );
  }
}
