import { AfterViewInit, Component, OnInit, EventEmitter } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { KeyValueService } from '../../services/key-value.service';
import { User } from '../../models/user.model';
import { ProfileService } from 'src/app/services/profile.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  isTopbarMenuPresent: boolean;
  showRegistrationOp: boolean;
  keyValuePair = new Map();
  user: User;
  CustomerName: any;
  profileImg: any;



  constructor(
    private router: Router,
    private keyService: KeyValueService,
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // console.log('checking', event, localStorage.getItem('currentUser'));
        if (event.url === '/signin' || event.url === '/signup' || event.url === '/forgetpassword') {
          this.isTopbarMenuPresent = false;
        } else {
          this.isTopbarMenuPresent = true;
          this.showRegistrationOp = localStorage.getItem('currentUser') ? false : true;
          this.fetchUser();
        }
      }
    });
    $(document).ready(() => {
      $(document).click(
        (event) => {
          const target = $(event.target);
          const mobileMenuOpen = $('.navbar-collapse').hasClass('show');
          console.log('jquery ==>>>', mobileMenuOpen, target.hasClass('navbar-toggler'));
          if (mobileMenuOpen === true && !target.hasClass('navbar-toggler')) {
            $('button.navbar-toggler').click();
          }
        }
      );
    });
  }

  ngOnInit() {
    this.getKeyValuePair().then((keyValue) => {
      if (this.keyValuePair) {
        // tslint:disable-next-line:max-line-length
        document.body.style.setProperty('--primaryBlueColour', this.keyValuePair.get('primaryBlueColour') ? this.keyValuePair.get('primaryBlueColour') : 'Blue')
      }
    });

    this.fetchUser();

    // document.getElementById('profileImage').innerText = this.profileImg;
  }

  fetchUser() {
    this.user = JSON.parse(localStorage.getItem('userAccount'));
    if(this.user){
      const F =  this.user.FirstName.charAt(0) ;
      const L =  this.user.LastName.charAt(0) ;
      this.CustomerName = F + L;
      this.profileImg =  this.CustomerName.toUpperCase();  
    }
   // console.log(this.profileImg);
  }

  getKeyValuePair(): Promise<any> {
    return new Promise((resolve, reject) => {
      const mapDataEmitter = new EventEmitter();
      this.keyService.GetAllValue().subscribe((data) => {
        // console.log('raw keyvalue data ==>', data);
        data.forEach((item) => {
          this.keyValuePair.set(item.VariableName, item.VariableValue);
        })
        resolve(this.keyValuePair);
      });
    })
  }

  loggingOut() {
    // console.log('mango');

    localStorage.clear();
    this.showRegistrationOp = true;
    this.router.navigateByUrl('/');
  }
  feature() {
    if (this.router.url === '/') {
      document.querySelector('#featureDiv').scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    else {
      this.router.navigate(['/']);
      setTimeout(() => {
        document.querySelector('#featureDiv').scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 800);
    }
  }
  greetings() {

    let greet = '';
    const myDate = new Date();
    const hrs = myDate.getHours();
    if (hrs < 12) {
      greet = 'Good Morning';
    }
    else if (hrs >= 12 && hrs < 17) {
      greet = 'Good Afternoon';
    }
    else if (hrs >= 17 && hrs <= 24) {
      greet = 'Good Evening';
    }


    const map = new Map()
    if(this.user){
      map.set('name', this.user.FirstName + ' ' + this.user.LastName);
    }
    map.set('wish', greet);

    return map;

  }

}
