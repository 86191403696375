import { Component, EventEmitter, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { KeyValueService } from '../services/key-value.service';


@Component({
  selector: 'app-terms-nservice',
  templateUrl: './terms-nservice.component.html',
  styleUrls: ['./terms-nservice.component.scss']
})
export class TermsNServiceComponent implements OnInit {
  footerContent: string;
  keyValuePair= new Map();

  constructor(
    private keyService: KeyValueService
  ) { }

  ngOnInit() {
    this.getKeyValuePair().then((keyValue) => {
      if (this.keyValuePair) {
        // console.log('within if, keyvaluepair', this.keyValuePair);
        
        // tslint:disable-next-line:max-line-length
        document.body.style.setProperty('--primaryBlueColour', this.keyValuePair.get('primaryBlueColour') ? this.keyValuePair.get('primaryBlueColour') : 'Blue');
        this.footerContent = environment.footerContent;
      }
    });
  }

  getKeyValuePair(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.keyService.GetAllValue().subscribe((data) => {
        // console.log('terms n services ==>', data);
        data.forEach((item) => {
          this.keyValuePair.set(item.VariableName, item.VariableValue);
        })
        resolve(this.keyValuePair);
      });
    })
  }

}
