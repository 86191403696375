import { catchError } from 'rxjs/operators';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { CrmCustomer } from '../models/CrmCustomer.model';

@Injectable({
    providedIn: 'root'
})
export class CrmCustomerService {
    private handleError: HandleError;
    public apiURL = environment.apiBaseUrl;

    constructor(private httpClient: HttpClient,
        private httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('CrmCustomer');
    }

    /**
     * Get All Crm Customer
     */
    public getCrmCustomer(): Observable<Array<CrmCustomer>> {
        const httpOptions = new HttpHeaders({
            'Content-Type': 'application/json',
            // tslint:disable-next-line: object-literal-key-quotes
            'Token': environment.Token
        });
        
        return this.httpClient.get(`${this.apiURL}/Crm`, {headers: httpOptions })
            .pipe(
                catchError(this.handleError<any>('getCrmCustomer', []))
            );
    }
}
