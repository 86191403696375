import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { LoginService } from '../../services/login.service';
import { SignupService } from '../../services/signup.service';
import { NotificationService } from 'src/app/services/notification.service';
import { RetrieveIPAddress } from '../../services/retrieveipaddress.service';
import { flatMap } from 'rxjs/operators';
import { CustomerService } from 'src/app/services/Customer.service';

import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, AfterViewInit {
  signinForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, Validators.min(6)])
  });

  hide = true;
  keyValuePair = new Map() ;
  shortenLink : string ;
  loading = false ;

  constructor(private loginService: LoginService,
    private signUpService: SignupService,
    private router: Router,
    private notification: NotificationService,
    private ipAddress: RetrieveIPAddress,
    private dataService: DataService,
    private customerService: CustomerService,
    private route: ActivatedRoute,
    ) {}
    
  ngOnInit() {    
    if (localStorage.getItem('currentUser')) {
      this.router.navigate(['/']);
    }
  }

  ngAfterViewInit(): void {
    this.keyValuePair = this.route.snapshot.data.data;
    this.shortenLink = this.route.snapshot.paramMap.get('shortenLink');
    if(this.keyValuePair){
      // tslint:disable-next-line:max-line-length
      document.body.style.setProperty('--primaryBlueColour', this.keyValuePair.get('primaryBlueColour') ? this.keyValuePair.get('primaryBlueColour') : 'Blue')
    }
    
  }


  // TODO: Fix this logic
  signIn() {
    this.loading = true ;
    let isNotLoggedIn: any;
    this.ipAddress.getIpAddress().pipe(flatMap(data => {
      return this.loginService.SignIn(this.signinForm.controls.email.value, this.signinForm.controls.password.value, data.ip);
    })).subscribe(response => {
      if (response === 'Account not found') {
        this.loading = false ;
        this.notification.show('Account Not Found');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('userAccount');
        localStorage.removeItem('userCrmAccount');
        isNotLoggedIn = false;
      } else {
       
        isNotLoggedIn = true;
        localStorage.setItem('currentUser', isNotLoggedIn);
        localStorage.setItem('userAccount', JSON.stringify(response));
        this.dataService.currentUserSubject.next(isNotLoggedIn);

        // Store the user Crm Account
        this.signUpService.getCrmAccount(this.signinForm.controls.email.value).subscribe(responseGetCrmAccount => {
          if (responseGetCrmAccount !== 'Account Not Found'){
            this.loading = false ;
            localStorage.setItem('userCrmAccount', JSON.stringify(responseGetCrmAccount));
            this.customerService.getSpCustomerByToken(environment.Token).subscribe(responseCustomer => {
              if (responseCustomer.length > 0){
                localStorage.setItem('customer', JSON.stringify(responseCustomer));
              }
            });
          }
          else{
            this.loading = false ;
            localStorage.removeItem('userCrmAccount');
            this.notification.show(responseGetCrmAccount);
          }
        });
        this.loading = false ;
        this.router.navigate([`/`]);
      }
    });
  }
}
