import { Component, OnInit } from '@angular/core';
import { AppNotification } from '../models/appnotification';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  footerContent: string;
  notifications: Array<AppNotification> = [];
  constructor() { }

  ngOnInit() {
    this.footerContent = environment.footerContent;
    const notification1 = new AppNotification();
    notification1.id = 1;
    notification1.notificationName = 'Fall Turkey - 2nd Lottery Opening Soon';

    const notification2 = new AppNotification();
    notification2.id = 1;
    notification2.notificationName = 'Deer Youth - Over the Counterpermits available in 3 Days';

    this.notifications.push(notification1);
    this.notifications.push(notification2);
  }

}
