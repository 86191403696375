import { AfterViewInit, Component, OnInit } from '@angular/core';
import { SignupService } from '../../services/signup.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RetrieveIPAddress } from '../../services/retrieveipaddress.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DataService } from '../../services/data.service';

// models
import { User } from '../../models/user.model';
import { CrmAccount } from '../../models/crmAccount.model';
import { ProfileService } from 'src/app/services/profile.service';
import { data } from 'jquery';
import * as $ from 'jquery';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})


export class ProfileComponent implements OnInit, AfterViewInit {
  hide = true;
  user: User;
  crmAccount: CrmAccount;
  loading = false;
  keyValuePair = new Map();

  signupForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    // tslint:disable-next-line:max-line-length
    password: new FormControl('', [Validators.required, Validators.min(6), Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$')]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required, Validators.pattern('^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$')]),
    address1: new FormControl('', [Validators.required]),
    address2: new FormControl(''),
    postalcode: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    dob: new FormControl(''),
    communication: new FormControl,
  });

  constructor(
    private noticationService: NotificationService,
    private dataService: DataService,
    private ipAddress: RetrieveIPAddress,
    private router: Router,
    private signUpService: SignupService,
    private activatedRoute: ActivatedRoute,
    private profileService: ProfileService
  ) {
  }

  ngOnInit() {
    //  this.user = JSON.parse(localStorage.getItem('userAccount'));

    this.crmAccount = JSON.parse(localStorage.getItem('userCrmAccount'));

    this.ipAddress.getIpAddress().subscribe(res => {
      if (this.crmAccount) {
        this.profileService.GetProfile(this.crmAccount.Username, this.crmAccount.Password, res.ip).subscribe(profiledata => {
          // console.log('profile=>', profiledata);
          this.user = profiledata;
          this.overrideDefaultValues(this.user);
        })
      } else {
        this.user = JSON.parse(localStorage.getItem('userAccount'));
        this.overrideDefaultValues(this.user);
      }
    })
    $(document).ready(() => {
      $('input[type=number]').on('focus', () => {
        $(this).on('keydown', (event) => {
          if (event.keyCode === 38 || event.keyCode === 40) {
            event.preventDefault();
          }
        });
      });
    });


  }

  ngAfterViewInit(): void {
    this.keyValuePair = this.activatedRoute.snapshot.data.data;
    if (this.keyValuePair) {
      // tslint:disable-next-line:max-line-length
      document.body.style.setProperty('--primaryBlueColour', this.keyValuePair.get('primaryBlueColour') ? this.keyValuePair.get('primaryBlueColour') : 'Blue')
    }
  }

  private overrideDefaultValues(user: User) {
    // console.log('user===>>', user);

    if (user.WorkPhone === '1111111111') {
      user.WorkPhone = '';
    }
    if (user.PostalCode === '11111') {
      user.PostalCode = '';
    }
    if (user.DOB === '0001-01-01T00:00:00') {
      user.DOB = '';
    }
    if (user.AddressLine1 === 'N/A') {
      user.AddressLine1 = '';
    }
  }

  get emailInput() { return this.signupForm.get('email'); }
  get passwordInput() { return this.signupForm.get('password'); }
  get firstNameInput() { return this.signupForm.get('firstName'); }
  get lastNameInput() { return this.signupForm.get('lastName'); }
  get phoneNumberInput() { return this.signupForm.get('phoneNumber'); }
  get addressLineInput1() { return this.signupForm.get('address1'); }
  get addressLineInput2() { return this.signupForm.get('address2'); }
  get postalCode() { return this.signupForm.get('postalcode'); }
  get country() { return this.signupForm.get('country'); }
  get dateOfBirth() {
    // let birth = this.signupForm.get('dob') ;
    // console.log('dob=>',typeof(birth.value));
    // console.log('dob=>',birth.value);
    // console.log('dob=>',new Date(birth.value));
    // console.log('dob stringfy',JSON.stringify(birth.value));


    return this.signupForm.get('dob');
  }
  get recieveCommunication() { return this.signupForm.get('communication'); }


  getPasswordInputError() {
    if (this.passwordInput.hasError('required')) {
      return 'A password is required.';
    }
    if (this.passwordInput.hasError('pattern')) {
      return 'Wrong Password'
    }
  }

  updateProfile(): void {

    this.loading = true;
    // console.log('valid signup=>',  );


    this.ipAddress.getIpAddress().subscribe(data => {
      if (this.user) {
        this.user.FirstName = this.firstNameInput.value;
        this.user.LastName = this.lastNameInput.value;
        this.user.Email = this.emailInput.value;
        this.user.Password = this.passwordInput.value;
        this.user.WorkPhone = this.phoneNumberInput.value;


        // console.log('type of',typeof(this.user.WorkPhone),this.user.WorkPhone);

        this.user.AddressLine1 = this.addressLineInput1.value;

        // console.log('type of',typeof(this.user.AddressLine1),this.user.AddressLine1);

        this.user.AddressLine2 = this.addressLineInput2.value;
        // console.log('type of',typeof(this.user.AddressLine2),this.user.AddressLine2);
        this.user.PostalCode = this.postalCode.value;

        // console.log('type of',typeof( this.user.PostalCode ), this.user.PostalCode );

        //this.user.country = this.country.value ;

        this.user.DOB = this.dateOfBirth.value;
        // console.log('dob',this.user.DOB);

        // this.user.Communication = this.recieveCommunication.value
        this.user.IpAddress = data.ip;

        // console.log('ip=>',data.ip);

        this.signUpService.updateCustomerProfileAndAccount(this.user, this.user.CustomerId, this.crmAccount.AccountID.toString())
          .subscribe(responseUpdateProfile => {

            if (responseUpdateProfile === 'Profile Updated Successfully') {
              localStorage.setItem('userAccount', JSON.stringify(this.user));
              this.crmAccount.Password = this.passwordInput.value;
              localStorage.setItem('userCrmAccount', JSON.stringify(this.crmAccount));
              // this.router.navigate(['/home']);
              this.loading = false;
              this.noticationService.show(responseUpdateProfile);
            } else {
              // TODO: add logging
              this.loading = false;
              this.noticationService.show(responseUpdateProfile);
            }
          });
      }
    });

  }
}
