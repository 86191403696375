import { catchError } from 'rxjs/operators';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { CrmCustomerSubCategoryType } from '../models/CrmCustomerSubCategoryType.model';

@Injectable({
    providedIn: 'root'
})
export class CrmCustomerSubCategoryTypeService {
    private handleError: HandleError;
    public apiURL = environment.apiBaseUrl;

    constructor(private httpClient: HttpClient,
        private httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('CrmCustomerSubCategoryType');
    }

    /**
     * Get All Crm Customer Sub Category Type 
     */
    public getCustomerSubCategoryType(): Observable<Array<CrmCustomerSubCategoryType>> {
        const httpOptions = new HttpHeaders({
            'Content-Type': 'application/json',
            // tslint:disable-next-line: object-literal-key-quotes
            'Token': environment.Token
        });
        
        return this.httpClient.get(`${this.apiURL}/CrmCustomerSubCategoryType`, {headers: httpOptions })
            .pipe(
                catchError(this.handleError<any>('getCustomerSubCategoryType', []))
            );
    }

    /*
    * Deletes the Customer Sub Category Type
    */
   public deleteCustomerSubCategoryType(CustomerSubCategoryTypeID: number): Observable<HttpResponse<null>> {
        const httpOptions = new HttpHeaders({
            'Content-Type': 'application/json',
            // tslint:disable-next-line: object-literal-key-quotes
            'Token': environment.Token
        });
        const params = new HttpParams()
            .set('CustomerSubCategoryTypeID', CustomerSubCategoryTypeID.toString());
        return this.httpClient.post(`${this.apiURL}/CrmCustomerSubCategoryType/DeleteCustomerSubCategoryType`, null, 
        { params, headers: httpOptions })
            .pipe(
                catchError(this.handleError<any>('DeleteCustomerSubCategoryType', []))
            );
    }

    /**
     * Creates Customer Sub Category Type 
     */
    public createCustomerSubCategoryType(customerSubCategoryType: CrmCustomerSubCategoryType): Observable<CrmCustomerSubCategoryType> {
        const httpOptions = new HttpHeaders({
            'Content-Type': 'application/json',
            // tslint:disable-next-line: object-literal-key-quotes
            'Token': environment.Token
        });
        return this.httpClient.post(`${this.apiURL}/CrmCustomerSubCategoryType/AddCustomerSubCategoryType`, 
        customerSubCategoryType, 
        { headers: httpOptions })
            .pipe(
                catchError(this.handleError<any>('createCustomerSubCategoryType', customerSubCategoryType))
            );
    }
}
