import { Component, Input, OnInit } from '@angular/core';
import { MyAccountComponent } from 'src/app/layouts/my-account/my-account.component';
import { Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-my-account-side-bar',
  templateUrl: './my-account-side-bar.component.html',
  styleUrls: ['./my-account-side-bar.component.scss']
})
export class MyAccountSideBarComponent implements OnInit {

  @Output() sendEditedDataEvent = new EventEmitter<any>();
  @Input() linkTitle:any ; 
  @Input() clickElement : object ;

  option = this.myAccountComponent.option;
  

  constructor(private myAccountComponent : MyAccountComponent) { }

  ngOnInit() {

  }

  crossButton(){
    this.myAccountComponent.optionClicked = false ;
  }

  sendEditedData(value){
    this.sendEditedDataEvent.emit(value);
  }

}
