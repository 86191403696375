import { Component, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'sanitizeHtml'
})
export class SanitizeHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}
    transform(value: string) {
        return value
        .split('&lt;').join('<')
        .split('&gt;').join('>')
        .split('&#39;').join('\'')
        .split('&amp;').join('&')
        .split('&raquo;').join('>>')
        .split('&nbsp;').join(' ');
    }
}