import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { HttpErrorHandler, HandleError } from '../services/http-error-handler.service';


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private apiURL = environment.apiBaseUrl;
  private handleError: HandleError;

  constructor(private httpClient: HttpClient, private httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('LoginService');
  }

  /**
   * Checks whether user is registered or not
   * @param username user login id
   * @param password user password
   * @param ipAddress user ip address
   */
  public SignIn(userName: string, password: string, ipAddress: string): Observable<any> {
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Token': environment.Token
    });

    const params = new HttpParams()
    .set('userName', userName)
    .set('password', password)
    .set('ipAddress', ipAddress);

    return this.httpClient.get(this.apiURL + '/CrmAccount', { params, headers: httpOptions })
    .pipe(
      catchError(this.handleError('CheckUser', []))
    );
  }

}
