import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './registration/login/login.component';
import { SignUpComponent } from './registration/sign-up/sign-up.component';
import { ForgetpasswordComponent } from './registration/forget-password/forgetpassword.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { ProfileComponent } from './registration/profile/profile.component';
import { HomeComponent } from './home/home.component';
import { SplashscreenlayoutComponent } from './layouts/splashscreenlayout/splashscreenlayout.component';
import { PrivacyPolicyComponent } from './privacypolicy/privacypolicy.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { SecondaryPageTemplateComponent } from './secondarypagetemplate/secondarypagetemplate.component';
import { RedirectComponent } from './redirect/redirect.component';
import { NotFoundComponent } from './notfound/notfound.component';
import { InitialPageComponent } from './layouts/initial-page/initial-page.component';
import { MyAccountComponent } from './layouts/my-account/my-account.component';
import { UserPostsResolve } from './Resolver/route.resolve';
import { AcceptCookieButtonComponent } from './components/accept-cookie-button/accept-cookie-button.component';

const routes: Routes = [
  // default route
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full',
  },
  {
    path: '',
    resolve: {
      data: UserPostsResolve
    },
    children: [
      { path: 'u/:hash', component: RedirectComponent, data: { category: 'DIRECT', redirecting: true } },
      { path: 'q/:hash', component: RedirectComponent, data: { category: 'QRCODE', redirecting: true } },
      // route for when URLP Redirect fails
      { path: 'notfound', component: NotFoundComponent, data: { redirecting: false } },
      // routes for use of the site itself
      { path: 'home', component: HomeComponent },
      {
        path: '',
        resolve: {
          data: UserPostsResolve
        }, component: InitialPageComponent
      },
      { path: 'signin', component: LoginComponent },
      { path: 'signup', component: SignUpComponent },
      { path: 'forgetpassword', component: ForgetpasswordComponent },
      { path: 'profile', component: ProfileComponent },
      // { path: 'privacypolicy', component: PrivacyPolicyComponent },
      { path: 'disclaimer', component: DisclaimerComponent },
      { path: 'secondarypagetemplate', component: SecondaryPageTemplateComponent },
      {
        path: 'myAccount',
        component: MyAccountComponent,
      },
      {
        path: 'cookieAccept',
        component: AcceptCookieButtonComponent,
      },
    ]
  },
  // { path: '', component: SplashscreenlayoutComponent, 
  //   children: [{ path: '', component: SplashScreenComponent}] 
  // },
  // routes for URLP Redirects

];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      enableTracing: false,// <-- debugging purposes only
      onSameUrlNavigation: 'reload'
    })],
  exports: [RouterModule],
  providers: [UserPostsResolve]
})
export class AppRoutingModule { }
