import { AfterViewInit, Component} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';
import { RetrieveIPAddress } from '../../services/retrieveipaddress.service';
import { SignupService } from '../../services/signup.service';
import { LoginService } from '../../services/login.service';
import { flatMap } from 'rxjs/operators';
import { DataService } from '../../services/data.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from 'src/app/services/Customer.service';
import { environment } from 'src/environments/environment';
import { ProfileService } from 'src/app/services/profile.service';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})

export class SignUpComponent implements AfterViewInit{
  hide = true;
  keyValuePair = new Map() ;
  loading = false ;
  signupForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    // tslint:disable-next-line:max-line-length
    password: new FormControl('', [Validators.required, Validators.min(6),Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$')]),
    confirmPassword: new FormControl('', [Validators.required, Validators.min(6)]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
  }, { validators: passwordMatchValidator });

  get emailInput() { return this.signupForm.get('email'); }
  get passwordInput() { return this.signupForm.get('password'); }
  get confirmPasswordInput() { return this.signupForm.get('confirmPassword'); }
  get firstNameInput() { return this.signupForm.get('firstName'); }
  get lastNameInput() { return this.signupForm.get('lastName'); }

  constructor(private loginService: LoginService,
              private dataService: DataService,
              private notificationService: NotificationService,
              private ipAddress: RetrieveIPAddress,
              private router: Router,
              private signUpService: SignupService,
              private route : ActivatedRoute,
              private customerService: CustomerService,
              private profileService : ProfileService,
  ) {  }

  ngAfterViewInit(): void {
    this.keyValuePair = this.route.snapshot.data.data;
    if(this.keyValuePair){
      // tslint:disable-next-line:max-line-length
      document.body.style.setProperty('--primaryBlueColour', this.keyValuePair.get('primaryBlueColour') ? this.keyValuePair.get('primaryBlueColour') : 'Blue')
    }
  }

  getEmailInputError() {
    if (this.emailInput.hasError('email')) {
      return 'Please enter a valid email address.';
    }
    if (this.emailInput.hasError('required')) {
      return 'An Email is required.';
    }
  }

  getPasswordInputError() {
    if (this.passwordInput.hasError('required')) {
      return 'A password is required.';
    }
    if (this.passwordInput.hasError('pattern')) {
      return 'Wrong Password'
    }
  }

  getConfirmPasswordInputError() {
    if (this.confirmPasswordInput.hasError('required')) {
      return 'Confirm password is required.';
    }
    if (this.confirmPasswordInput.value !== this.passwordInput.value) {
      return 'Password Mismatch. hi';
    }

    if (this.signupForm.hasError('passwordMismatch')) {
      return 'Password Mismatch.' ;
    }
    
  }

  getFirstNameInputError() {
    if (this.firstNameInput.hasError('required')) {
      return 'First Name is required.';
    }
  }

  getLastNameInputError() {
    if (this.lastNameInput.hasError('required')) {
      return 'Last Name is required.';
    }
  }
  

  /**
   * signup new user
   */
  singup(): void {

    this.loading = true ;

    this.ipAddress.getIpAddress().subscribe(data => {

     

      const user = {
        FirstName: this.firstNameInput.value,
        LastName: this.lastNameInput.value,
        UserName: this.emailInput.value,
        Password: this.passwordInput.value,
        AddressLine1: '',
        country:'',
        AddressLine2: '',
        PostalCode: '',
        Email: this.emailInput.value,
        WorkPhone: '',
        Fax: '',
        IpAddress: data.ip,
        From: '',
        FromName: '',
        ActivateAccountUrl: '',
        CustomerId: '',
        DOB : '',
      };

      let isNotLoggedIn: any;
      this.signUpService.createUser(user).subscribe(responseSignUp => {      
        if (responseSignUp === 'Account created.') {
          this.ipAddress.getIpAddress().pipe(flatMap(data => {
            return this.loginService.SignIn(user.Email, user.Password, data.ip);
          })).subscribe(responseLogin => {
            if (responseLogin === 'Account not found') {
              this.loading = false ;
              localStorage.removeItem('currentUser');
              localStorage.removeItem('userAccount');
              localStorage.removeItem('userCrmAccount');
              this.notificationService.show('Account Not Found');
            } else {
              isNotLoggedIn = true;
              localStorage.setItem('currentUser', isNotLoggedIn);
              localStorage.setItem('userAccount', JSON.stringify(responseLogin));
              this.dataService.currentUserSubject.next(true);

              // Store the user Crm Account
              this.signUpService.getCrmAccount(user.Email).subscribe(responseGetCrmAccount => {
                if (responseGetCrmAccount !== 'Account Not Found'){
                  this.loading = false ;
                  localStorage.setItem('userCrmAccount', JSON.stringify(responseGetCrmAccount));
                  this.customerService.getSpCustomerByToken(environment.Token).subscribe(responseCustomer => {
                    if (responseCustomer.length > 0){
                      localStorage.setItem('customer', JSON.stringify(responseCustomer));
                    }
                  });
                }
                else{
                  this.loading = false ;
                  localStorage.removeItem('userCrmAccount');
                  this.notificationService.show(responseGetCrmAccount);
                }
              });
              this.router.navigate(['/']);
            }
          });
        } else {
          // TODO: add logging
          this.notificationService.show(responseSignUp);
        }
        
      });
    });
  }
}

export const passwordMatchValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
  return formGroup.get('password').value === formGroup.get('confirmPassword').value ?
    null : { 'passwordMismatch': true };
};

