import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService  implements OnDestroy {

  // Configuration api subscription
  private _configState: Subscription;
  /**
   * Constructor
   * @param toast  {MatSnackBar}
   * @param configService {ConfigurationService}
   */
  constructor( 
    private toast:MatSnackBar) {}
  
  /**
   * Unsubscribe from the config state
   * when the component is destroyed, and remove
   * the in-memory parameters.
   */
  ngOnDestroy() {
    this._configState.unsubscribe();
  }

  /**
   * Display a MatSnackbar notification and return the reference.
   * Will set the duration to the global configuration if present.
   * @param message {string}
   * @param buttonLabel {string}
   * @returns {MatSnackBarRef}
   */
  messeage: string = "Email";
  show(message: string, buttonLabel: string = 'Ok'): MatSnackBarRef<any> {
    const toastTimeout = 8000;
    if (toastTimeout > 0) {
      return this.toast.open(message, buttonLabel, {
        duration: toastTimeout
      });
    } else {
      return this.toast.open(message, buttonLabel, {
      });
    }
  }
}
