import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { TooltipPosition } from '@angular/material/tooltip';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { MyAccountComponent } from 'src/app/layouts/my-account/my-account.component';
import { UrlsService } from 'src/app/services/urls.service';
import { ActivatedRoute } from '@angular/router';


@Component({
    selector: 'app-analytics-side-bar',
    templateUrl: './analytics-side-bar.component.html',
    styleUrls: ['./analytics-side-bar.component.scss']
})
export class AnalyticsSideBarComponent implements OnInit, AfterViewInit {


    startDate = new Date()
    endDate = new Date()

    monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    //
    dateValidation = true;

    alwaysShowCalendars = true;
    showRangeLabelOnInput = true;
    keepCalendarOpeningWithRange = true;
    ranges: any = {
        start: new FormControl(new Date()),
        end: new FormControl(this.GetTomorrowDate()),
        Today: [moment().subtract(1, 'days'), moment()],
        Yesterday: [moment().subtract(2, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(7, 'days'), moment()],
        'Last 30 Days': [moment().subtract(30, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [
            moment()
                .subtract(1, 'month')
                .startOf('month'),
            moment()
                .subtract(1, 'month')
                .endOf('month')
        ],
        'Last 3 Month': [
            moment()
                .subtract(3, 'month')
                .startOf('month'),
            moment()
                .subtract(1, 'month')
                .endOf('month')
        ]
    };
    //

    selected: any =
        {
            start: Date(),
            end: this.GetTomorrowDate()
        };





    // graph

    QrGraphDropDown = false;
    ClickGraphDropDown = false;
    ClickGraphDropDown1 = false;

    QrGraphCloseCondition = false;
    ClickGraphCloseCondition = false;
    ClickGraphCloseCondition1 = false;

    firstGraphOpen = true;
    secondGraphOpen = true;
    firstGraphShow = true;
    secondGraphShow = true;


    QrCodeObject = [];
    OrtherObject = [];

    QrCodeGraphData = [];
    OrtherGraphData = [];
    tempLength = 0;
    tempLength1 = 0;

    ReferralsByCountXAxis = [];
    ReferralsByCountYAxis = [];

    day = [];
    apiDateRange: object;
    apiReferralsByCount: object;

    keyValuePair: any;

    constructor(
        private urlService: UrlsService,
        private myAccount: MyAccountComponent,
        private activatedRoute: ActivatedRoute
    ) { // graph referrer checking

        this.date();

    }


    ngOnInit() {


    }

    ngAfterViewInit(): void {
        this.keyValuePair = this.activatedRoute.snapshot.data.data;
        if (this.keyValuePair) {
            // console.log('getting clr==>', this.keyValuePair.get('primaryBlueColour'));

            // tslint:disable-next-line:max-line-length
            document.body.style.setProperty('--primaryBlueColour', this.keyValuePair.get('primaryBlueColour') ? this.keyValuePair.get('primaryBlueColour') : 'Blue')
        }

    }

    public graph() {
        const chart1 = new Highcharts.Chart({
            chart: {
                renderTo: 'graph1',
                type: 'column'
            },

            xAxis: {
                title: {
                    text: 'Day'
                },
                categories: this.day,

                crosshair: true,
                tickInterval: 1
            },
            yAxis: {
                title: {
                    text: 'No. of Times Clicked'
                },
                tickInterval: 1
            },
            series: [{
                name: 'Click Count',
                data: this.OrtherGraphData,
                type: 'column'
            }],
            credits: {
                enabled: false
            }
        });


        const chart2 = new Highcharts.Chart({
            chart: {
                renderTo: 'graph2',
                type: 'column'
            },

            xAxis: {
                title: {
                    text: 'Day'
                },
                categories: this.day,


                crosshair: true,
                tickInterval: 1
            },
            yAxis: {
                title: {
                    text: 'No. of Times Scanned'
                },
                // tickInterval: 1
            },
            series: [{
                name: 'Scan Count',
                data: this.QrCodeGraphData,
                type: 'column'
            }],
            credits: {
                enabled: false
            }
        });

        const chart3 = new Highcharts.Chart({
            chart: {
                renderTo: 'graph3',
                type: 'column'
            },
            //  title: {
            //      text: 'Referrel Graph'
            //  },
            xAxis: {
                // title: {
                //     text: 'Referrer'
                // },
                categories: this.ReferralsByCountXAxis,


                crosshair: true,
                tickInterval: 1
            },
            yAxis: {
                title: {
                    text: 'Referrels'
                },
                // tickInterval: 1
            },
            series: [{
                name: 'Referrer count',
                data: this.ReferralsByCountYAxis,
                type: 'column'
            }],
            credits: {
                enabled: false
            }
        });
    }


    date() {



        // let startingDate = new Date(new Date().setDate(new Date().getDate() - 10));
        let startingDate = new Date();
        let endingDate = new Date();
        this.QrCodeGraphData = [];
        this.QrCodeGraphData.length = 0;

        this.OrtherGraphData = [];
        this.OrtherGraphData.length = 0;
        this.day = [];
        this.day.length = 0;
        this.ReferralsByCountXAxis = [];
        this.ReferralsByCountYAxis = [];

        // console.log('from date function ==',this.QrCodeGraphData,this.OrtherGraphData);


        if (this.selected) {


            startingDate = new Date(this.selected.start);
            endingDate = new Date(this.selected.end);

        }

        // const sDate = ((startingDate.getUTCMonth() + 1) + '/' + (startingDate.getUTCDate()) + '/' + (startingDate.getUTCFullYear()));
        // const eDate = ((endingDate.getUTCMonth() + 1) + '/' + (endingDate.getUTCDate()) + '/' + (endingDate.getUTCFullYear()));
        var dd = String(startingDate.getDate()).padStart(2, '0');
        var mm = String(startingDate.getMonth() + 1).padStart(2, '0');
        var yyyy = startingDate.getFullYear();
        const sDate = mm + '/' + dd + '/' + yyyy;
        dd = String(endingDate.getDate() + 1).padStart(2, '0');
        mm = String(endingDate.getMonth() + 1).padStart(2, '0');
        yyyy = endingDate.getFullYear();
        const eDate = mm + '/' + dd + '/' + yyyy;

        // tslint:disable-next-line:max-line-length
        if (this.myAccount.clickedObject) {
            this.urlService.GetUrlLogByDateRange(JSON.parse(JSON.stringify(this.myAccount.clickedObject)).UrlID, sDate, eDate).subscribe((data) => {

                this.apiDateRange = data;
                this.QrCodeObject.length = 0;
                this.OrtherObject.length = 0;
                this.tempLength = 0;
                this.tempLength1 = 0;

                for (let i = 0; i < JSON.parse(JSON.stringify(this.apiDateRange)).length; i++) {

                    if (this.apiDateRange[i].Referrer === 'QRCODE') {

                        this.QrCodeObject.push(this.apiDateRange[i]);
                        this.tempLength1 = this.tempLength1 + 1;
                    }
                    else {

                        this.OrtherObject.push(this.apiDateRange[i]);
                        this.tempLength = this.tempLength + 1;


                    }
                };

                for (const j = startingDate; j <= endingDate; j.setDate(j.getDate() + 1)) {

                    const dateMonth = (this.monthNames[(j.getMonth())] + (j.getDate()))
                    this.day.push(dateMonth);
                    let qrMatching = 0;
                    let anotherMatching = 0;

                    for (let k = 0; k < this.tempLength1; k++) {

                        const date = new Date(this.QrCodeObject[k].DateCreated);

                        if (j.toDateString() === date.toDateString()) {

                            qrMatching = qrMatching + 1;
                        }
                    }
                    this.QrCodeGraphData.push(qrMatching);

                    for (let k = 0; k < this.tempLength; k++) {

                        const date = new Date(this.OrtherObject[k].DateCreated);

                        if (j.toDateString() === date.toDateString()) {
                            anotherMatching = anotherMatching + 1;
                        }
                    }
                    this.OrtherGraphData.push(anotherMatching);

                }
                this.graph();

            })

            // tslint:disable-next-line:max-line-length
            this.urlService.GetReferralsByCount(JSON.parse(JSON.stringify(this.myAccount.clickedObject)).UrlID, sDate, eDate).subscribe((data) => {
                this.apiReferralsByCount = data;
                // console.log('api referellal count == ',JSON.parse(JSON.stringify(this.apiReferralsByCount)).length);

                for (let i = 0; i < JSON.parse(JSON.stringify(this.apiReferralsByCount)).length; i++) {
                    this.ReferralsByCountXAxis.push(this.apiReferralsByCount[i].Referrer);
                    this.ReferralsByCountYAxis.push(this.apiReferralsByCount[i].ReferralCount);
                }
                this.graph();
                // console.log('another graph data==>', data);
            })
        }
    }


    cross(id: number) {
        if (id === 1) {
            this.QrGraphCloseCondition = true;
        }
        else if (id === 2) {
            // this.secondGraphShow = !this.secondGraphShow;
            this.ClickGraphCloseCondition = true;
        }
        else if (id === 3) {
            this.ClickGraphCloseCondition1 = true;
        }

    }

    down(id: number) {
        if (id === 1) {
            this.firstGraphOpen = !this.firstGraphOpen;
        }

        else if (id === 2) {
            this.secondGraphOpen = !this.secondGraphOpen;
        }

    }
    dropDownCondition(value: boolean) {
        this.QrGraphDropDown = value;
    }
    ClickdropDownCondition(value: boolean) {
        this.ClickGraphDropDown = value;

    }
    ClickdropDownCondition1(value: boolean) {
        this.ClickGraphDropDown1 = value;

    }

    GetTomorrowDate(){
        let today = new Date();
        let tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow;
    }

}