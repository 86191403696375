import { catchError } from 'rxjs/operators';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Customer } from '../models/Customer.model';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {
    private handleError: HandleError;
    public apiURL = environment.apiBaseUrl;

    constructor(private httpClient: HttpClient,
        private httpErrorHandler: HttpErrorHandler) {
        this.handleError = httpErrorHandler.createHandleError('Customer');
    }

    /**
     * Get Specific Customer By Token
     */
    public getSpCustomerByToken(token: string): Observable<Array<Customer>> {
        const httpOptions = new HttpHeaders({
            'Content-Type': 'application/json',
            // tslint:disable-next-line: object-literal-key-quotes
            'Token': environment.Token
        });
        const params = new HttpParams()
            .set('token', token);
        return this.httpClient.get(`${this.apiURL}/Customer`, { params, headers: httpOptions })
            .pipe(
                catchError(this.handleError<any>('getCustomer', []))
            );
    }

    public getCustomerId(){
        return JSON.parse(localStorage.getItem('userAccount')) ? JSON.parse(localStorage.getItem('userAccount')).CustomerId : null;
    }
}
