import { AfterViewInit, ChangeDetectorRef, Component, OnInit, AfterViewChecked } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { CustomerService } from './../../services/Customer.service';
import { UrlsService } from './../../services/urls.service';
import { DataService } from 'src/app/services/data.service';
import { data } from 'jquery';
import { KeyValue } from 'src/app/models/key-value.model';
import { NotificationService } from 'src/app/services/notification.service';


export interface TableData {
  link: object;
  shortUrl: string;
  orgUrl: object;
}

const ELEMENT_DATA: TableData[] = [
  { link: { info: 'Bawarchi_biryanis' }, shortUrl: 'https://urlp.io/a', orgUrl: { link: '' } },
  { link: { info: 'In_store' }, shortUrl: 'https://urlp.io/b', orgUrl: { link: '' } },
  { link: { info: 'Table-1' }, shortUrl: 'https://urlp.io/c', orgUrl: { link: '' } },
  { link: { info: 'Table-2' }, shortUrl: 'https://urlp.io/d', orgUrl: { link: '' } }
];

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit, AfterViewInit, AfterViewChecked {

  urlField = '^(http|https):\/\/(.*)';

  urlForm: FormGroup = new FormGroup({
    link: new FormControl('', [Validators.required]),
    url: new FormControl('', [Validators.required])
  });

  displayedColumns: string[] = ['link', 'shortUrl', 'orgUrl', 'option'];
  dataSource = ELEMENT_DATA;

  apiData: any[];
  optionClicked = false;
  clickedObject: object;
  option: number;
  searchText = '';
  tableData: any[];
  link: string;
  customerId: number;
  linkTitle: any = [];
  clickElement: any;
  t: any;
  keyValuePair = new Map();
  currentUser: any;
  titleInput = '';
  shortenInput = '';
  shortCallLoading = false;

  constructor(
    private router: Router,
    private urlService: UrlsService,
    private customerService: CustomerService,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private noticationService: NotificationService,
    public cd: ChangeDetectorRef
  ) {
    // this.urlService.getAllUrl().subscribe((urlData) => {
    //   this.apiData = urlData ;
    // })
    // this.getTableData();

    // this.customerId = this.customerService.getCustomerId();
  }



  ngOnInit() {
    this.getTableData();
    this.customerId = this.customerService.getCustomerId();
    this.currentUser = localStorage.getItem('currentUser');
    if (!this.currentUser) {
      this.router.navigate(['/']);
    } else {
      this.getTableData();
    }
    //  this.keyValuePair = this.activatedRoute.snapshot.data.data;

  }

  ngAfterViewInit(): void {
    this.keyValuePair = this.activatedRoute.snapshot.data.data;
    if (this.keyValuePair) {
      // tslint:disable-next-line:max-line-length
      document.body.style.setProperty('--primaryBlueColour', this.keyValuePair.get('primaryBlueColour') ? this.keyValuePair.get('primaryBlueColour') : 'Blue')
    }
  }

  ngAfterViewChecked(): void {
    // Called after every check of the component's view. Applies to components only.
    // Add 'implements AfterViewChecked' to the class.
    this.cd.detectChanges();
  }

  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  // }

  urloption(v: object, id: number) {

    this.clickElement = v;
    this.optionClicked = true;
    this.clickedObject = v;
    this.option = id;
    // console.log('which url clicked  ',this.clickedObject);

  }

  updateTableData(value) {
    this.getTableData();
  }

  getTableData() {
    this.urlService.getAllUrl().subscribe((urlData) => {
      console.log('urlData', urlData);
      this.apiData = urlData;
      this.tableData = urlData;
    })
  }

  filterTableData() {
    if(this.apiData){
      this.tableData = this.apiData.filter((e) => new RegExp(this.searchText, 'gi').test(e.Title));

    }
  }
  isUrlValid(userInput) {
    const regexQuery = '^(http|https):\/\/(.*)';
    const url = new RegExp(regexQuery, 'i');
    return url.test(userInput);
  }
  shortenUrl(title: any, link: any) {
    // console.log(title , link);

    if (this.isUrlValid(link)) {
      this.shortCallLoading = true;
      const createUrlBody = {
        Url: link,
        Title: title,
        CustomerID: this.customerId
      };
      this.urlService.createUrl(createUrlBody).subscribe((response) => {
        this.shortCallLoading = false;
        this.getTableData();

        //   setTimeout(() => {
        //     window.location.reload(); 
        // },1000);
      });
      this.titleInput = '';
      this.shortenInput = '';
    }
    else {
      this.noticationService.show('Url should contain http:// or https://');
    }


  }
}