import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public currentUser: Observable<any>;
  public currentUserSubject: BehaviorSubject<any>;
  public listSoruce = new BehaviorSubject<any>(null);
  // public list$ = this.listSoruce.asObservable();

  constructor() {
    this.currentUserSubject = new BehaviorSubject<any>(localStorage.getItem('currentUser'));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }
  setList(v){ 
    console.log("serviceT:",v); 
    this.listSoruce.next(v)
  }
}
