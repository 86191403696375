import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { KeyValueService } from '../services/key-value.service';
import { KeyValue } from '../models/key-value.model';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {

  SplashImage: string;
  BannerContent: string;
  CarouselIndicators: string;
  constructor(@Inject(DOCUMENT) private document, private router: Router, private keyValueService: KeyValueService) {
  }

  ngOnInit() {
    this.keyValueService.GetAllValue().subscribe((keyValue) => {

      if (keyValue && keyValue.length) {
        const numberOfBanners = Number(keyValue.find(x=>x.VariableName === 'NumberOfMobileBanners').VariableValue);
        let bannerContent = '';
        let carouselIndicators = '';
        for (let i = 1; i <= numberOfBanners; i++){
          bannerContent += '<div class="carousel-item' + (i===1 ? ' active' : '') + '">';
          const bannerImage = keyValue.find(x=>x.VariableName === 'MobileBanner' + i).VariableValue;
          bannerContent += '<img class="w-100" src="' + bannerImage + '" id="assetSplashSlider' + i + '" alt="slide">';
          bannerContent += '</div>';

          carouselIndicators += '<li data-target="#carouselIndicators' + i + '" data-slide-to="' + i + '" class="' 
          + (i===1 ? ' active' : '') +'"></li>';
        }
        this.BannerContent = bannerContent;
        this.CarouselIndicators = carouselIndicators;
        console.log('if banner content', this.BannerContent);
        
      } else if (!keyValue || keyValue.length <= 0) {
        //TODO: Add proper logging
        console.log('Invalid token');
      }
    });
    // setTimeout(() => {
    //   this.router.navigate(['/signin']);
    // }, 10000);
  }
}
