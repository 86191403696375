import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpErrorHandler, HandleError } from '../services/http-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class RetrieveIPAddress {

  private handleError: HandleError;

  constructor(private httpClient: HttpClient, private httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('RetrieveIPAddress');
  }

  /**
   * Retrieves user ip address
   */
  public getIpAddress(): Observable<any> {
    return this.httpClient.get<{ ip: string }>('https://jsonip.com')
      .pipe(
        catchError(this.handleError('getIpAddress', []))
      );
  }
}
