import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';

// Services
import { environment } from '../../environments/environment';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';

// models
import { KeyValue } from '../models/key-value.model';
import { keyValue } from '../models/key-value.model';
import {HttpClientModule} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class KeyValueService {
  private handleError: HandleError;
  public apiURL = environment.apiBaseUrl;
  public keyVlaueCache: KeyValue[];

  constructor(private httpClient: HttpClient,
    private httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('KeyValueService');
  }

  /**
   * Retrieves ket values pairs for the given token
   */
  public GetAllValue(): Observable<KeyValue[]> {
    // console.log('getting map from service');
    if(this.keyVlaueCache) {
      return of(this.keyVlaueCache);
    } else {
      const httpOptions = new HttpHeaders({
        'Content-Type': 'application/json',
        'Token': environment.Token
      });
      return this.httpClient.get<KeyValue[]>(this.apiURL + '/keyvalue', { headers: httpOptions })
        .pipe(
          tap((data) => {
            this.keyVlaueCache = data;
          }),
          catchError(this.handleError('GetAllValue', []))
        );
    }
    
  }
}