import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild, ElementRef, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UrlsService } from './../../services/urls.service';
import { CustomerService } from './../../services/Customer.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { KeyValue } from 'src/app/models/key-value.model';
import { MatDialog } from '@angular/material/dialog';
import { PrivacyPolicyComponent } from 'src/app/privacypolicy/privacypolicy.component';
import { TermsNServiceComponent } from 'src/app/terms-nservice/terms-nservice.component';
import { CookieService } from 'ngx-cookie-service';
import { data } from 'jquery';
import { NotificationService } from 'src/app/services/notification.service';
import { CookieAndFooterServiceService } from 'src/app/services/cookie-and-footer-service.service';

@Component({
  selector: 'app-initial-page',
  templateUrl: './initial-page.component.html',
  styleUrls: ['./initial-page.component.scss']
})
export class InitialPageComponent implements OnInit, AfterViewInit, AfterViewChecked {
  
  cookieIsActive = true;
  cookieCrossButton = true ;
  @ViewChild('template', {static: false}) modalTemplate: any;
  modalRef: BsModalRef;
  isLogIn : boolean ;
  sortenLink : string ;
  customerId : number;
  map : any ;
  loading = false ;
  contentWithImg = [];
  httpErrMsg = 'Url should start with http or https';

  contentWithImgStatic = [
    {src: './../../../assets/images/cardImg.png',
     heading: 'Inspire trust',
     // tslint:disable-next-line:max-line-length
     para: 'With more clicks comes increased brand recognition and consumer trust in your communications—which in turn inspires even more engagement with your links. (It’s a wonderful cycle.)'},
    {src: './../../../assets/images/cardImg01.png',
     heading: 'Boost results',
     // tslint:disable-next-line:max-line-length
     para: 'On top of better deliverability and click-through, rich link-level data gives you crucial insight into your link engagement so your team can make smarter decisions around its content and communications.'},
    {src: './../../../assets/images/cardImg02.png',
     heading: 'Gain control',
     // tslint:disable-next-line:max-line-length
     para: 'Take credit for your content and learn more about how it’s consumed by enabling auto-branding—a feature that ensures your brand remains in any link someone shortens pointing to your website.'}
  ];
  // plainContent = [
  //   {heading: 'Why Urlp.io?',
  //    items: [
  //      'Urlp 101',
  //      'Integrations & API',
  //      'Enterprise class',
  //      'Pricing'
  //    ]},
  //   {heading: 'Solutions',
  //    items: [
  //      'Social media',
  //      'Digital marketing',
  //      'Customer service',
  //      'For developers'
  //    ]},
  //   {heading: 'Resources',
  //    items: [
  //      'Blog',
  //      'Developers'
  //    ]},
  //   {heading: 'Company',
  //    items: [
  //      'About Urlp',
  //      'Our team'
  //    ]}
  // ];

  

  urlField = '^(http|https):\/\/(.*)';
  
  shortenForm: FormGroup = new FormGroup({
    url: new FormControl('', [Validators.required, Validators.pattern(this.urlField)])
  });

  popUpForm: FormGroup = new FormGroup({
    title: new FormControl('', [Validators.required]),
    link: new FormControl('', [Validators.required])
  });

  constructor (
    private modalService: BsModalService,
    private router: Router,
    private urlService: UrlsService,
    private customerService: CustomerService,
    private activatedRoute : ActivatedRoute,
    public dialog: MatDialog,
    private cookieService : CookieService,
    private noticationService: NotificationService,
    public cd: ChangeDetectorRef,
    public cookieAndFooterServiceService : CookieAndFooterServiceService
    ){
     
    // this.router.events.subscribe(event => {
    //   if(event instanceof NavigationEnd){
    //     this.isLogIn = localStorage.getItem('currentUser') ? true : false;
    //   }
    // });
    // this.customerId = this.customerService.getCustomerId();
  }
  ngOnInit() {
    // this.router.events.subscribe(event => {
    //   if(event instanceof NavigationEnd){
    //     this.isLogIn = localStorage.getItem('currentUser') ? true : false;
    //   }
    // });
    // this.isLogIn = localStorage.getItem('currentUser') ? true : false;
    this.customerId = this.customerService.getCustomerId();
    if(localStorage.getItem('cookies')){
      this.cookieIsActive = false;
    }
    // this.myKeyValue.forEach(item=>{
    //   this.map.set(item.VariableName,item.VariableValue) ;
    // })
     if(localStorage.getItem('shortenLink')){
       setTimeout(()=>{
         this.shortenUrl(localStorage.getItem('shortenLink'));
         localStorage.removeItem('shortenLink');
       }, 100)
     }
     
  }

  ngAfterViewInit(): void {
    // document.getElementById('titleInput')
    this.map = this.activatedRoute.snapshot.data.data;
    if(this.map){
      document.body.style.setProperty('--primaryBlueColour', this.map.get('primaryBlueColour') ? this.map.get('primaryBlueColour') : 'Blue')
     
      try {
      this.contentWithImg = JSON.parse(this.map.get('FEATURES'));
     } catch(e) {
      this.contentWithImg = [
        {src: './../../../assets/images/cardImg.png',
         heading: 'Inspire trust',
         // tslint:disable-next-line:max-line-length
         para: 'With more clicks comes increased brand recognition and consumer trust in your communications—which in turn inspires even more engagement with your links. (It’s a wonderful cycle.)'},
        {src: './../../../assets/images/cardImg01.png',
         heading: 'Boost results',
         // tslint:disable-next-line:max-line-length
         para: 'On top of better deliverability and click-through, rich link-level data gives you crucial insight into your link engagement so your team can make smarter decisions around its content and communications.'},
        {src: './../../../assets/images/cardImg02.png',
         heading: 'Gain control',
         // tslint:disable-next-line:max-line-length
         para: 'Take credit for your content and learn more about how it’s consumed by enabling auto-branding—a feature that ensures your brand remains in any link someone shortens pointing to your website.'}
      ];
     }
      
    }
    

  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  cookieAccept(){
    this.cookieIsActive = false ;
    this.cookieService.set('cookieSet','Urlp project cookies');
    localStorage.setItem('cookies','true');
    // this.router.navigate(['/cookieAccept']);
    
  }

  popupSubmit(title: any,link : any)
  {
    
    if(this.isUrlValid(link))
    {
      this.loading = true ;
      const createUrlBody = {
        Url: link,
        Title: title,
        CustomerID: this.customerId
      };
      this.urlService.createUrl(createUrlBody).subscribe((response) => {
        // console.log('createUrl data =>', response);
     
        this.closeModal();
        localStorage.removeItem('shortenLink');
        this.loading = false ;
        this.router.navigate(['/myAccount'])
       
      });
    }
    else 
    {
      this.noticationService.show(this.httpErrMsg) ;
    }
  
   
  }

  public shortenUrl(id :string){
    this.sortenLink= id;
    this.isLogIn = localStorage.getItem('currentUser') ? true : false;
    if(this.isLogIn && this.isUrlValid(id) ) {
      this.openModal();
    }
    else if(!this.isUrlValid(id) && this.isLogIn)
    {
      this.noticationService.show(this.httpErrMsg) ;
      
    }
    else 
    {
      localStorage.setItem('shortenLink', id)
      this.router.navigate(['/signin']);
    }
  }
  isUrlValid(userInput) {
    const regexQuery = '^(http|https):\/\/(.*)';
    const url = new RegExp(regexQuery,'i');
    return url.test(userInput);
}

  openModal(){
    if(this.modalTemplate){
      this.popUpForm.setValue({
        'title': '',
        'link': this.sortenLink
      })
      this.modalRef = this.modalService.show(this.modalTemplate);
      document.getElementById('title-Input').focus();
    }
  }

  closeModal(){
    this.modalRef.hide();
  }

  scrollDown(event){
    // console.log('event ==>', event);
    window.scrollTo(0, event.target.offsetTop-60)
  }
  openDialog(comp)
  {
    if(comp === 'privacy'){
      this.dialog.open(PrivacyPolicyComponent);
    }else if(comp === 'terms'){
      this.dialog.open(TermsNServiceComponent);
    }
  }
  

  cookieCross(){
    this.cookieCrossButton = false ;
  }
}