import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MyAccountComponent } from 'src/app/layouts/my-account/my-account.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-qrcode-side-bar',
  templateUrl: './qrcode-side-bar.component.html',
  styleUrls: ['./qrcode-side-bar.component.scss']
})
export class QRcodeSideBarComponent implements OnInit, AfterViewInit {
  clickedObject =this.myAccountComponent.clickedObject? JSON.parse(JSON.stringify(this.myAccountComponent.clickedObject)):{};
  value = this.clickedObject.ShortQrCode;
  fullValue = this.clickedObject.LongQrCode;
  url = this.clickedObject.Url;
  shrl = this.clickedObject.ShortenedUrl;
  urlInfoDropDown = false ;
  origineQrDropDown = false ;
  shortQrDropDown = false ;

  urlInfoCloseCondition = true ;
  originalUrlCondition = true ;
  shortUrlCondition = true ;

  keyValuePair : any ;
  


  constructor(
      private myAccountComponent : MyAccountComponent,
      private activatedRoute : ActivatedRoute,
    ) {
      console.log('clickdobject', this.clickedObject);
      
  }
  
  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.keyValuePair = this.activatedRoute.snapshot.data.data;
    if(this.keyValuePair){
      // tslint:disable-next-line:max-line-length
      document.body.style.setProperty('--primaryBlueColour', this.keyValuePair.get('primaryBlueColour') ? this.keyValuePair.get('primaryBlueColour') : 'Blue')
    }
  }

  ClickdropDownCondition(value : boolean)
  {
    this.urlInfoDropDown = value ;
  }
  OriginaldropDownCondition(value : boolean)
  {
    this.origineQrDropDown = value ;
  }
  ShortdropDownCondition(value : boolean)
  {
    this.shortQrDropDown = value ;
  }
  urlInfoClose(flag : Number)
  {
    if(flag === 1)
    {
      this.urlInfoCloseCondition = false ;
    }
    else if(flag === 2)
    {
      this.originalUrlCondition = false ;
    }
    else if(flag === 3)
    {
      this.shortUrlCondition = false ;
    }
    
  }
  
}
