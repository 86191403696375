import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { User } from '../models/user.model';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandler, HandleError } from '../services/http-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  public apiURL = environment.apiBaseUrl;
  private handleError: HandleError;
  
  constructor(
    private httpClient: HttpClient,
    private httpErrorHandler: HttpErrorHandler
    ) {
    this.handleError = httpErrorHandler.createHandleError('ProfileService');
  }

  public GetProfile(userName: string, password: string, ipAddress: string): Observable<any> {
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Token': environment.Token
    });
    const params = new HttpParams()
    .set('userName', userName)
    .set('password', password)
    .set('ipAddress', ipAddress);

    return this.httpClient.get(`${this.apiURL}/CrmAccount`, { params, headers: httpOptions })
      .pipe(
        catchError(this.handleError<any>('getProfile'))
      );
  }

  // public async GetProfile(userName: string, password: string, ipAddress: string): Promise<any>{
  //   const httpOptions = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Token': environment.Token
  //   });

  //   const params = new HttpParams()
  //   .set('userName', userName)
  //   .set('password', password)
  //   .set('ipAddress', ipAddress);

  //   try {
  //     const response = await this.httpClient.get(this.apiURL + '/CrmAccount', { params, headers: httpOptions })
  //     .toPromise();
  //     return Promise.resolve(response);
  //   } catch (err) {
  //     return Promise.reject(err.console.error || 'Server Error');
  //   }
  // }
}
