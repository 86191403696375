import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { environment } from '../environments/environment';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { ForgetpasswordComponent } from './registration/forget-password/forgetpassword.component';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { HttpErrorHandler } from './services/http-error-handler.service';
import { LoginComponent } from './registration/login/login.component';
import { LoaderComponent } from './loader/loader.component';
import { MaterialModule } from './material.module';
import { MessageService } from './services/message.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProfileComponent } from './registration/profile/profile.component';
import { RatingModule } from 'ngx-rating';
import { ReactiveFormsModule } from '@angular/forms';
import { SanitizeHtmlPipe } from './sanitize-html-pipe';
import { SafePipe } from './safe.pipe';
import { SplashscreenlayoutComponent } from './layouts/splashscreenlayout/splashscreenlayout.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SignUpComponent } from './registration/sign-up/sign-up.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { PrivacyPolicyComponent} from './privacypolicy/privacypolicy.component';
import { DisclaimerComponent} from './disclaimer/disclaimer.component';
import { CustomRouteDirective } from './custom-route.directive';
import { SecondaryPageTemplateComponent } from './secondarypagetemplate/secondarypagetemplate.component';
import { RedirectComponent } from './redirect/redirect.component';
import { NotFoundComponent } from './notfound/notfound.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { InitialPageComponent } from './layouts/initial-page/initial-page.component';

import { MyAccountComponent } from './layouts/my-account/my-account.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MyAccountSideBarComponent } from './components/my-account-side-bar/my-account-side-bar.component';
import { EditSideBarComponent } from './components/edit-side-bar/edit-side-bar.component';
import { QRcodeSideBarComponent } from './components/qrcode-side-bar/qrcode-side-bar.component';
import { AnalyticsSideBarComponent } from './components/analytics-side-bar/analytics-side-bar.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import {MatDialogModule} from '@angular/material/dialog';
import { ComponentsModule } from './components/components.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { CookieService } from 'ngx-cookie-service';
import { RouterModule } from '@angular/router';
import { TermsNServiceComponent } from './terms-nservice/terms-nservice.component';
import { AcceptCookieButtonComponent } from './components/accept-cookie-button/accept-cookie-button.component';



@NgModule({
  declarations: [
    AppComponent,
    RedirectComponent,
    NotFoundComponent,
    LoginComponent,
    HomeComponent,
    SignUpComponent,
    LoaderComponent,
    ForgetpasswordComponent,
    SplashScreenComponent,
    ProfileComponent,
    SanitizeHtmlPipe,
    SafePipe,
    SplashscreenlayoutComponent,
    PrivacyPolicyComponent,
    DisclaimerComponent,
    CustomRouteDirective,
    SecondaryPageTemplateComponent,
    NotificationsComponent,
    InitialPageComponent,
    MyAccountComponent,
    MyAccountSideBarComponent,
    EditSideBarComponent,
    QRcodeSideBarComponent,
    AnalyticsSideBarComponent,
    TermsNServiceComponent,
    
    
  ],
  entryComponents : [
    PrivacyPolicyComponent,
    TermsNServiceComponent
  ],

  imports: [
    BrowserModule,
    MaterialModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule,
    RatingModule,
    FlashMessagesModule.forRoot(),
    ComponentsModule,
    ModalModule.forRoot(),
    HighchartsChartModule,
    NgxDaterangepickerMd.forRoot(),
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule ,
    MatCheckboxModule,
    RouterModule.forRoot([]),
  ],
  providers: [
    HttpErrorHandler,
    MessageService,
    DatePipe,
    CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
