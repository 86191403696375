import { Component, OnInit, ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RedirectComponent implements OnInit {
  ngOnInit() {}
}
