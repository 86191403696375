import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-secondarypagetemplate',
  templateUrl: './secondarypagetemplate.component.html',
  styleUrls: ['./secondarypagetemplate.component.scss']
})

export class SecondaryPageTemplateComponent implements OnInit {
  footerContent: string;

  ngOnInit() {
    this.footerContent = environment.footerContent;
  }
}
