import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { User } from '../models/user.model';
import { CrmCustomerSubCategoryType } from 'src/app/models/CrmCustomerSubCategoryType.model';
import { CrmCustomerSubCategoryTypeService } from 'src/app/services/CrmCustomerSubCategoryType.service';
import { KeyValue } from 'src/app/models/key-value.model';
import { CrmCustomer } from '../models/CrmCustomer.model';
import { CrmCustomerService } from '../services/CrmCustomer.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  footerContent:string;
  navigationSubscription: any;
  name: string;
  user: User;
  CustomerID: number;
  crmCustomerSubCategoryTypeServices: Array<CrmCustomerSubCategoryType>;
  crmCurrentCustomerSubCategoryTypeServices: Array<CrmCustomerSubCategoryType>;
  crmCustomerServices: Array<CrmCustomer>;
  keyValues: Array<KeyValue>;

  // Flags
  navTrackAttendanceFlag: boolean;
  
  constructor(
    private router: Router,
    private crmCustomerSubCategoryTypeService: CrmCustomerSubCategoryTypeService,
    private crmCustomerService: CrmCustomerService,
    ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {      
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }

  initialiseInvites() {
  }

  ngOnInit() {
    this.footerContent = environment.footerContent;
    if (!localStorage.getItem('currentUser')) {
      this.router.navigate(['/signin']);
    }
    this.user = JSON.parse(localStorage.getItem('userAccount'));
    this.name = this.user.FirstName;
    this.CustomerID = Number(this.user.CustomerId);
    localStorage.setItem('EventCategory', 'Home');
    this.keyValues = JSON.parse(localStorage.getItem('keyValues'))
    this.LoadServiceData();
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  private LoadServiceData(){
    // Get all Crm Customer Sub Category Type
    this.crmCustomerSubCategoryTypeService.getCustomerSubCategoryType()
    .subscribe((crmCustomerSubCategoryTypeServices) => {
      this.crmCustomerSubCategoryTypeServices = crmCustomerSubCategoryTypeServices;
      localStorage.setItem('CrmCustomerSubCategoryTypeServices', JSON.stringify(this.crmCustomerSubCategoryTypeServices));
      
      // filter the dataset for current customer
      this.crmCurrentCustomerSubCategoryTypeServices = this.crmCustomerSubCategoryTypeServices.filter(x=>x.CustomerID === this.CustomerID);
      localStorage.setItem('CrmCurrentCustomerSubCategoryTypeServices', JSON.stringify(this.crmCurrentCustomerSubCategoryTypeServices));

      // Set Navigation
      this.SetNavigationBasedOnRole();

    })

    this.crmCustomerService.getCrmCustomer()
    .subscribe((crmCustomerServices) => {
      this.crmCustomerServices = crmCustomerServices;
      localStorage.setItem('crmCustomerServices', JSON.stringify(this.crmCustomerServices));
    })
  }

  gotoMemberDirectory(route){
    this.router.navigateByUrl('/' + route);
  }

  private SetNavigationBasedOnRole(){
    
    if (this.keyValues.length > 0){
      if (this.keyValues.filter(x=>x.VariableName === 'InnerCircleChairTag').length > 0 
      && this.keyValues.filter(x=>x.VariableName === 'InnerCircleTag').length > 0){
        this.navTrackAttendanceFlag = (
          this.crmCurrentCustomerSubCategoryTypeServices.filter(
            x=>x.CustomerSubCategory.toUpperCase() === 
            this.keyValues.find(x=>x.VariableName === 'InnerCircleChairTag').VariableValue.toUpperCase()
            && x.CustomerCategory.toUpperCase() === 
            this.keyValues.find(x=>x.VariableName === 'InnerCircleTag').VariableValue.toUpperCase()
          ).length > 0
        );
      }
    }
  }
}
