import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})

export class NotFoundComponent implements OnInit {
  footerContent: string;

  ngOnInit() {
    this.footerContent = environment.footerContent;
  }
}
