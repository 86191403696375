import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandler, HandleError } from '../services/http-error-handler.service';
import { User } from '../models/user.model';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SignupService {
  private apiURL = environment.apiBaseUrl;
  private handleError: HandleError;

  constructor(private httpClient: HttpClient,
    private httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('SignupService');
  }

  /**
   * Registers new user
   * @param user user details
   */
  public createUser(user: User): Observable<any> {
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      // tslint:disable-next-line: object-literal-key-quotes
      'Token': environment.Token
    });

    return this.httpClient.post(`${this.apiURL}/CrmAccount`, user, { headers: httpOptions })
      .pipe(
        catchError(this.handleError<any>('createUser', user))
      );
  }

  /*
  * Update Customer Profile and Account
  */
  public updateCustomerProfileAndAccount(user: User, crmCustomerID: string, crmAccountID: string): Observable<any> {
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      // tslint:disable-next-line: object-literal-key-quotes
      'Token': environment.Token
    });
    const params = new HttpParams()
      .set('crmCustomerID', crmCustomerID)
      .set('crmAccountID', crmAccountID);

    return this.httpClient.post(`${this.apiURL}/CrmAccount`, user, { params, headers: httpOptions })
      .pipe(
        catchError(this.handleError<any>('updateCustomerProfileAndAccount', user))
      );
  }

  /*
  * Forgot Password
  */
  public forgotPassword(email: string): Observable<any> {
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      // tslint:disable-next-line: object-literal-key-quotes
      'Token': environment.Token
    });
    const params = new HttpParams()
      .set('forgotPasswordEmail', email)
      .set('action', 'RetrievePassword');

    return this.httpClient.get(`${this.apiURL}/CrmAccount`, { params, headers: httpOptions })
      .pipe(
        catchError(this.handleError<any>('forgotPassword', []))
      );
  }


  /*
  * Get Crm Account
  */
  public getCrmAccount(email: string): Observable<any> {
    const httpOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      // tslint:disable-next-line: object-literal-key-quotes
      'Token': environment.Token
    });
    const params = new HttpParams()
      .set('emailAddress', email);

    return this.httpClient.get(`${this.apiURL}/CrmAccount`, { params, headers: httpOptions })
      .pipe(
        catchError(this.handleError<any>('getCrmAccount', []))
      );
  }
}
