import { EventEmitter, Injectable } from '@angular/core';  
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';  
import { Observable, of } from 'rxjs';  
import { keyValue, KeyValue } from '../models/key-value.model';
import { KeyValueService } from '../services/key-value.service';

@Injectable()  
export class UserPostsResolve  implements Resolve<any> {  
    
  constructor(private keyService : KeyValueService ) {}  
  
 resolve(route : ActivatedRouteSnapshot) : Promise<any>
  {
    return new Promise((resolve, reject) => {
      const mapDataEmitter = new EventEmitter();
      this.keyService.GetAllValue().subscribe((data) => {
        const map = new Map;
          data.forEach((item) => {
            map.set(item.VariableName, item.VariableValue);
          })
          // console.log('api formated data', map);
          resolve(map);
      });
    })
  }
  //   console.log('Route Resolve');
  //   this.keyService.GetAllValue().subscribe((data) => {

  //       console.log('keyData=>',data);
        
  //       data.forEach((item)=>{
  //         this.map.set(item.VariableName,item.VariableValue) ;
  //       })
  //       console.log("Map=>",this.map);
        
  //       console.log("key-value-pair=>",data);
        
  //     })
  //   return this.map;  
  // }  
  // resolve(route : ActivatedRouteSnapshot) : Map<string,string>
  // {
  //     this.keyService.GetAllValue().subscribe((data) => {

  //       console.log('keyData=>',data);
        
  //       data.forEach((item)=>{
  //         this.map.set(item.VariableName,item.VariableValue) ;
  //       })
  //       console.log("Map=>",this.map);
  //       console.log('add url',this.map.get('addUrl'));
        
        
  //       console.log("key-value-pair=>",data);
        
  //     })
  //     return this.map;  

  // }
}  