import { Component, OnInit, EventEmitter } from '@angular/core';
import { KeyValueService } from '../../services/key-value.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  keyValuePair = new Map();

  constructor(
    private keyService: KeyValueService,
  ) {

  }

  ngOnInit() {
    this.getKeyValuePair().then((keyValue) => {
      if (this.keyValuePair) {
        // tslint:disable-next-line:max-line-length
        document.body.style.setProperty('--primaryBlueColour', this.keyValuePair.get('primaryBlueColour') ? this.keyValuePair.get('primaryBlueColour') : 'Blue')
      }
    });
  }

  getKeyValuePair(): Promise<any> {
    return new Promise((resolve, reject) => {
      const mapDataEmitter = new EventEmitter();
      this.keyService.GetAllValue().subscribe((data) => {
        // console.log('raw keyvalue data ==>', data);
        data.forEach((item) => {
          this.keyValuePair.set(item.VariableName, item.VariableValue);
        })
        resolve(this.keyValuePair);
      });
    })
  }

}
