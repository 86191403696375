import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoaderService } from 'src/app/loader/loader.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SignupService } from '../../services/signup.service';


@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.scss']
})
export class ForgetpasswordComponent implements OnInit, AfterViewInit {

  keyValuePair = new Map() ;
  loading : boolean = false ;

  forgetPassForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required])
  });

  hide = true;

  get emailInput() { return this.forgetPassForm.get('email'); }

  constructor(
    private loginService: LoginService,
    private signUpService: SignupService,
    private router: Router,
    private loader: LoaderService,
    private notification: NotificationService,
    private activatedRoute : ActivatedRoute
    ) {

  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.keyValuePair = this.activatedRoute.snapshot.data.data;
    if(this.keyValuePair){
      // tslint:disable-next-line:max-line-length
      document.body.style.setProperty('--primaryBlueColour', this.keyValuePair.get('primaryBlueColour') ? this.keyValuePair.get('primaryBlueColour') : 'Blue')
    }
  }

  getEmailInputError() {
    if (this.emailInput.hasError('email')) {
      return 'Please enter a valid email address.';
    }
    if (this.emailInput.hasError('required')) {
      return 'An Email is required.';
    }
  }

  passwordRetrive(): void {
    this.loading = true ;
    this.signUpService.forgotPassword(this.emailInput.value)
      .subscribe(responseForgotPassword => {
       
        this.notification.show(responseForgotPassword);
        this.loading = false ;
        
      });
   
  }
}
