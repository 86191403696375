import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopbarComponent } from './topbar/topbar.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { AcceptCookieButtonComponent } from './accept-cookie-button/accept-cookie-button.component';





@NgModule({
  declarations: [
    TopbarComponent,
    FooterComponent,
    AcceptCookieButtonComponent,
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    TopbarComponent,
    FooterComponent
  ]
})
export class ComponentsModule { 
  signPage = false ;
  signin = false ;
}
