import { AfterViewInit ,Component, OnInit } from '@angular/core';
import { MyAccountComponent } from 'src/app/layouts/my-account/my-account.component';
import { UrlsService } from './../../services/urls.service';
import { CustomerService } from './../../services/Customer.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-side-bar',
  templateUrl: './edit-side-bar.component.html',
  styleUrls: ['./edit-side-bar.component.scss'],
  // animations: []
})

export class EditSideBarComponent implements OnInit, AfterViewInit {

  @Output() getEditedDataEvent = new EventEmitter<any>();
  
  clickedItem: any = this.myAccountComponent.clickedObject;
  customerId : number;
  keyValuePair = new Map();
  editCallLoading = false;
  urlField = '^(http|https):\/\/(.*)';
  updateForm: FormGroup = new FormGroup({
    // tslint:disable-next-line:max-line-length
    title: new FormControl('', [Validators.required]),
    url: new FormControl('', [Validators.required, Validators.pattern(this.urlField)]),
  });
  

  constructor(
    private myAccountComponent : MyAccountComponent,
    private urlService: UrlsService,
    private customerService: CustomerService,
    private notification: NotificationService,
    private activatedRoute : ActivatedRoute
  ) {
    // this.updateForm.markAllAsTouched();
    this.customerId = this.customerService.getCustomerId();
    // console.log('clicked item', this.clickedItem);
    console.log('this.clickedItem',this.clickedItem);
  }

  ngOnInit() {
    if(this.clickedItem){
      if(this.clickedItem.Title){
        this.updateForm.controls.title.setValue(this.clickedItem.Title);
      }
      if(this.clickedItem.Url){
        this.updateForm.controls.url.setValue(this.clickedItem.Url);
      }
    }    
  }

  ngAfterViewInit(): void {
    const keyValuePair = this.activatedRoute.snapshot.data.data || new Map;
    if(keyValuePair){
      // tslint:disable-next-line:max-line-length
      document.body.style.setProperty('--primaryBlueColour', keyValuePair.get('primaryBlueColour') ? keyValuePair.get('primaryBlueColour') : 'Blue')
    }
    this.keyValuePair = keyValuePair;
  }

  updatelink(title : string, url:string){
    this.editCallLoading = true;
    const editUrlBody = {
      Url: url,
      Title: title,
      CustomerID: this.customerId
    };
    console.log('clicked item', this.clickedItem);
    
    const urlID = JSON.parse(JSON.stringify(this.clickedItem)).UrlID;
    this.urlService.updateUrl(editUrlBody, urlID).subscribe((response) => {
      this.editCallLoading = false;
      if(response){
        this.myAccountComponent.optionClicked = false ;
        this.notification.show('Link updated successfully.');
        this.getEditValue(response);
      }
    });
  }

  getEditValue(value) {
    this.getEditedDataEvent.emit(value);
  }
}