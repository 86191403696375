import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})

export class DisclaimerComponent implements OnInit {
  footerContent: string;

  ngOnInit() {
    this.footerContent = environment.footerContent;
  }
}
